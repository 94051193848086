import React from 'react';

function aboutPage() {
    return (
        <body>
            <header>
            </header>
            <div>
                <h1> About Us :) </h1>
            </div>

        </body>
    );
}

export default aboutPage;