import React from 'react';
function HomePage() {
    return (
        <body>
            <header>
            </header>
            <div>
                <h1> Home Page Test </h1>
            </div>

        </body>

    );
}

export default HomePage;