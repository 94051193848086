import React, { useEffect, useRef } from 'react';
import './nav.css'; // Ensure you have appropriate styles defined in this CSS file

function MakeBubbles() {
    const bubbleContainerRef = useRef(null);

    useEffect(() => {
        createBubbles(50);
        const handleScroll = () => updateBubbles();

        window.addEventListener('scroll', handleScroll);
        const animationId = animateBubbles();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            cancelAnimationFrame(animationId);
        };
    });

    function createBubbles(numBubbles) {
        const bubbleContainer = bubbleContainerRef.current;
        for (let i = 0; i < numBubbles; i++) {
            const bubble = document.createElement('div');
            bubble.classList.add('bubble');
            const size = Math.random() * 20 + 10; // Random size between 10px and 30px
            bubble.style.width = `${size}px`;
            bubble.style.height = `${size}px`;
            bubble.style.left = `${Math.random() * 100}%`;
            bubble.style.top = `${Math.random() * 100}vh`;
            bubble.style.transition = 'top 0.5s linear';
            bubbleContainer.appendChild(bubble);

            setTimeout(() => {
                bubble.style.top = `${parseFloat(bubble.style.top) - 10}vh`;
            }, 0);
        }
    }

    function updateBubbles() {
        const bubbles = document.querySelectorAll('.bubble');
        bubbles.forEach(bubble => {
            const initialTop = parseFloat(bubble.style.top);
            const newTop = initialTop - window.scrollY / 1000;

            if (newTop < -50) {
                bubble.remove();
            } else {
                bubble.style.top = `${newTop}vh`;
            }
        });
    }

    function animateBubbles() {
        const bubbles = document.querySelectorAll('.bubble');
        bubbles.forEach(bubble => {
            const initialTop = parseFloat(bubble.style.top);
            bubble.style.top = `${initialTop - 0.05}vh`;
            if (initialTop < -10) {
                bubble.style.top = '100vh';
            }
        });
        return requestAnimationFrame(animateBubbles);
    }

    return <div ref={bubbleContainerRef} className="bubbles"></div>;
}

export default MakeBubbles;
