import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './recurring/nav.js';
import MakeBubbles from './recurring/make-bubbles.js';
import HomePage from './pages/home/home.js';
import AboutPage from './pages/about/about.js';
import Inventory from './pages/inventory/inventory.js';
import NewOrder from './pages/user-order/new-order.js'; // Ensure this is correctly capitalized as per React component naming conventions

import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/new-order" element={<NewOrder />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
      <MakeBubbles />
    </div>
  );
}

export default App;
