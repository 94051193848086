import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
    return (
        <div>
            {/* Google Fonts are usually better placed in the HTML head, but they can be included here for component encapsulation */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="nav.css" />

            <nav className="nav-container">
                <div className="nav-title">Booch Bros</div>
                <div className="nav-buttons">
                    {/* Using React Router's Link component to navigate */}
                    <Link to="/inventory" className="nav-link">Inventory</Link>
                    <Link to="/new-order" className="nav-link">New Order</Link>
                    <Link to="/" className="nav-link">Home Page</Link>
                    <Link to="/about-us" className="nav-link">About Us</Link>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;