import React, { useState } from 'react';
import './inventory.css';

function Inventory() {
    const [orders, setOrders] = useState([]);
    const [formVisible, setFormVisible] = useState(false);
    const [formData, setFormData] = useState({
        drinkType: 'Kombucha', // default value as per your existing form
        buyer: '',
        volume: '',
        flavor: '',
        price: '',
        sellDate: '',
        startDate: '',
        secondary: '',
        readyDate: ''
    });
    const [visibleStats, setVisibleStats] = useState({}); // Track visibility of order stats

    const generateUniqueID = () => {
        let id;
        do {
            id = Math.floor(Math.random() * 90000000) + 10000000; // Generate an 8-digit number
        } while (orders.some(order => order.id === id));
        return id;
    };

    const openForm = () => setFormVisible(true);
    const closeForm = () => setFormVisible(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const addOrder = () => {
        const newOrder = {
            ...formData,
            id: generateUniqueID(),
            workTime: Math.ceil((new Date(formData.readyDate) - new Date(formData.startDate)) / (1000 * 60 * 60 * 24)),
            orderNumber: orders.length > 0 ? Math.max(...orders.map(order => order.orderNumber)) + 1 : 1
        };

        setOrders(prevOrders => [...prevOrders, newOrder]);
        closeForm();
    };

    const deleteOrder = (orderId) => {
        setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
    };

    const toggleStats = (orderId) => {
        setVisibleStats(prev => ({
            ...prev,
            [orderId]: !prev[orderId]
        }));
    };

    const renderOrderBox = (order) => {
        const isStatsVisible = visibleStats[order.id];
        return (
            <div key={order.id} className="order-box" onClick={() => toggleStats(order.id)}>
                <button className="order-number" title={order.price}>{order.orderNumber}</button>
                <button className="delete-button" onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling to the order box
                    deleteOrder(order.id);
                }}>X</button>
                <h2>{order.flavor}</h2>
                <h1>{order.drinkType}</h1>
                <p>{order.buyer} - {order.volume}</p>
                <p className="order-date">Order Date: {order.sellDate || 'N/A'}</p>
                <p className="ready-date">Ready Date: {order.readyDate}</p>
                {isStatsVisible && (
                    <div className="order-stats" id={`stats-${order.id}`}>
                        <p>Start Date: {order.startDate || 'N/A'}</p>
                        <p>Secondary Date: {order.secondary || 'N/A'}</p>
                        <p>Ready Date: {order.readyDate}</p>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <button onClick={openForm}>Order Now</button>
            {formVisible && (
                <div id="form-container" className="form-popup">
                    <form className="form-container">
                        {/* All inputs inside the form for proper submission handling */}
                        <h2>Add New Order</h2>
                        <input type="text" name="buyer" value={formData.buyer} onChange={handleInputChange} placeholder="Buyer" />
                        <input type="text" name="volume" value={formData.volume} onChange={handleInputChange} placeholder="Volume" />
                        <input type="text" name="flavor" value={formData.flavor} onChange={handleInputChange} placeholder="Flavor" />
                        <input type="text" name="price" value={formData.price} onChange={handleInputChange} placeholder="Price" />
                        <input type="date" name="sellDate" value={formData.sellDate} onChange={handleInputChange} />
                        <input type="date" name="startDate" value={formData.startDate} onChange={handleInputChange} />
                        <input type="date" name="secondary" value={formData.secondary} onChange={handleInputChange} />
                        <input type="date" name="readyDate" value={formData.readyDate} onChange={handleInputChange} />
                        <button type="button" className="form-btn" onClick={addOrder}>Add Order</button>
                        <button type="button" className="form-cancel" onClick={closeForm}>Close</button>
                    </form>
                </div>
            )}
            {orders.map(renderOrderBox)}
        </div>
    );
}

export default Inventory;
