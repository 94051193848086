import React, { useState } from 'react';
import './new_order.css';  // Adjust path as necessary
import '../../recurring/nav.css'; // Adjust path as necessary

function NewOrder() {
    const [orders, setOrders] = useState([]);
    const fetchOrders = () => {
        fetch('orders.json')
            .then(response => response.json())
            .then(data => {
                const updatedOrders = data.map(order => ({
                    ...order,
                    id: order.id || generateUniqueID()
                }));
                setOrders(updatedOrders);
                // displayOrders(updatedOrders); // Assuming you need to implement or adjust this
            })
            .catch(error => console.error('Error fetching orders:', error));
    };




    const generateUniqueID = () => {
        let id;
        do {
            id = Math.floor(Math.random() * 90000000) + 10000000; // Generate an 8-digit number
        } while (orders.some(order => order.id === id));
        return id;
    };

    return (
        <div>
            <div className="container">
                <h1>New Orders</h1>
                {/* Assuming openForm should open a form, implement this if needed */}
                {/* <button className="plus-button" onClick={openForm}>+</button> */}
            </div>
            <div id="form-container"></div>
        </div>
    );
}

export default NewOrder;
